import { Inject, Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "@app/shared/services/auth.service";
import { Observable, filter, of } from "rxjs";
import { NewLabel } from "../bulk-ship/model/new-label";
import { OrderToShip } from "../core";
import { OrderDocument, OrderDocumentType } from "../data/order-document";
import { OrderToShipDto } from "../data/order-to-ship.dto";
import { ShippingProviderProductDto } from "../data/shipping-provider-product.dto";
import { SelectInvoiceDialogComponent } from "./select-invoice-dialog/select-invoice-dialog.component";

@Injectable({
    providedIn: "root",
})
export class SharedShippingService {
    private readonly matDialog = inject(MatDialog);

    constructor(@Inject("authService") private authService: AuthService) {}

    getSelectedInvoiceId(
        order: OrderToShipDto | OrderToShip,
        existingLabel: NewLabel | undefined,
    ): Observable<OrderDocument | null | undefined> {
        if (!this.requiresInvoiceSelection(order)) {
            return of(
                order.Documents?.filter(({ Type }) => Type === OrderDocumentType.Invoice).length === 1
                    ? order.Documents?.filter(({ Type }) => Type === OrderDocumentType.Invoice)[0]
                    : null,
            );
        }

        return this.matDialog
            .open(SelectInvoiceDialogComponent, { data: { order, existingLabel }, autoFocus: false })
            .afterClosed()
            .pipe(filter((invoice): invoice is OrderDocument => !!invoice));
    }

    getNewLabelError(
        order: OrderToShipDto,
        product: ShippingProviderProductDto | undefined,
    ): string {
        let errorMessage = "";
        if (product?.ProductServices.some(({ RequiresUserInput }) => !!RequiresUserInput)) {
            errorMessage = "text.additional_services_data_missing";
        }
        if (this.requiresInvoiceSelection(order)) {
            errorMessage = "text.invoice_selection_missing";
        }

        return errorMessage;
    }

    requiresInvoiceSelection(order: OrderToShipDto | OrderToShip): boolean {
        const hasMultipleInvoices =
            order.Documents !== null &&
            order.Documents.filter(({ Type }) => Type === OrderDocumentType.Invoice).length > 1;
        const isOwnCountry = order.ShippingAddress.CountryCode === this.authService.getCountryCode();

        return hasMultipleInvoices && !isOwnCountry;
    }
}

<h3 mat-dialog-title>{{ "title.select_invoice" | translate }}</h3>
<mat-dialog-content>
    <span [innerHTML]="'text.html_select_invoice_for_label' | translate"></span>
    <br />
    <table class="material smaller">
        <thead data-id="table-header">
            <th></th>
            <th>{{ "label.invoicenumber" | translate }}</th>
            <th>{{ "label.invoice_date" | translate }}</th>
            <th>{{ "label.download" | translate }}</th>
        </thead>

        <tbody>
            @for (document of selectableDocuments; track document.Id) {
                <tr (click)="onClickRow(document.Id)">
                    <td>
                        <mat-radio-group name="selection" [formControl]="selectionControl">
                            <mat-radio-button [value]="document.Id"></mat-radio-button>
                        </mat-radio-group>
                    </td>
                    <td>
                        {{ document.Number }}
                    </td>
                    <td>{{ document.Date | date }}</td>
                    <td>
                        <button mat-icon-button (click)="onClickDownload(document.Uri); $event.stopPropagation()">
                            <mat-icon fontIcon="fa-download"></mat-icon>
                        </button>
                    </td>
                </tr>
            }
        </tbody>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" mat-dialog-close>{{ "button.cancel" | translate }}</button>
    <button mat-flat-button color="primary" (click)="onClickSelect()">{{ "button.select" | translate }}</button>
</mat-dialog-actions>

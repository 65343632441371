export enum OrderDocumentType {
    Invoice = "Invoice",
    CancellationInvoice = "CancellationInvoice",
}

export interface OrderDocumentDetail {
    Id: number;
    ExternalReference: string;
    Amount: number;
}

export interface OrderDocument {
    Id: number;
    ParentId: number | null;
    Type: OrderDocumentType;
    Number: string;
    Date: string;
    ExternalReference: string;
    Uri: string;
    Details: OrderDocumentDetail[];
}
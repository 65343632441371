import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { OrderToShipDto } from "@app/app/shipping/data/order-to-ship.dto";
import { TranslateModule } from "@ngx-translate/core";
import { NewLabel } from "../../bulk-ship/model/new-label";
import { OrderDocumentType } from "../../data/order-document";

@Component({
    selector: "bb-select-invoice-dialog",
    templateUrl: "./select-invoice-dialog.component.html",
    styleUrls: ["./select-invoice-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        DatePipe,
        ReactiveFormsModule,
        MatRadioModule,
        MatButtonModule,
        MatIcon,
        TranslateModule,
    ],
})
export class SelectInvoiceDialogComponent {
    protected readonly data = inject<{ order: OrderToShipDto; existingLabel: NewLabel | undefined }>(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef);

    protected selectionControl = new FormControl<number | null>(this.data.existingLabel?.SelectedInvoiceId || null);
    protected readonly selectableDocuments = this.data.order.Documents?.filter(
        ({ Type }) => Type === OrderDocumentType.Invoice,
    );

    onClickRow(id: number) {
        this.selectionControl.setValue(id);
    }

    onClickDownload(url: string) {
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    onClickSelect() {
        if (this.selectionControl.value == null) {
            return;
        }

        const orderDoc = this.data.order.Documents?.find(({ Id }) => Id === this.selectionControl.value) || null;
        this.dialogRef.close(orderDoc);
    }
}

import { Injectable } from "@angular/core";
import { ShipmentRepository } from "@app/app/shipping/core/repository";
import { SharedShippingService } from "@app/app/shipping/shared/shared-shipping.service";
import {
    ClientStorageService,
    ErrorHandlerService,
    EventsService, InteractionService,
    Message,
    MessagingService,
    NavigationService,
    PlatformChannelService,
    TranslationService,
} from "@bb-core/service";
import { firstValueFrom } from "rxjs";
import { Address, OrderToShip, ShippingProductService, ShippingStationOptions } from "../../entity";
import { SingleOrderShippedEvent } from "../../event";
import { SingleShipPresenter } from "./presenter";

export class ShipSingleOrderRequest {
    public OrderToShip: OrderToShip = null;
    public ShippingDate: Date = null;
    public ProductServices: ShippingProductService[] = [];

    constructor(obj?: Partial<ShipSingleOrderRequest>) {
        ctor(this, obj);
    }
}

@Injectable({providedIn: "root"})
export class ShipSingleOrderUseCase implements IUseCase<ShipSingleOrderRequest, SingleShipPresenter> {

    constructor(private readonly translator: TranslationService,
                private readonly messagingService: MessagingService,
                private readonly shippingRepository: ShipmentRepository,
                private readonly platformChannel: PlatformChannelService,
                private readonly clientStorageService: ClientStorageService,
                private readonly navigationService: NavigationService,
                private readonly eventService: EventsService,
                private readonly errorHandler: ErrorHandlerService,
                private readonly interaction: InteractionService,
                private readonly sharedShippingService: SharedShippingService,
    ) {
    }

    public async execute(request: ShipSingleOrderRequest,
                         presenter?: SingleShipPresenter,
    ): Promise<void> {
        if (!(await this.validateRequest(request))) {
            presenter?.singleShipFailed();
            return;
        }

        if (request.OrderToShip.AlreadyShipped && !(await this.confirmDoubleShip(request.OrderToShip.ShippingDate))) {
            presenter?.singleShipFailed();
            return;
        }

        request.OrderToShip.ShippingDate = request.ShippingDate;

        const selectedInvoice = await firstValueFrom(this.sharedShippingService.getSelectedInvoiceId(request.OrderToShip, undefined));
        if (selectedInvoice === undefined) {
            presenter?.singleShipFailed();
            return;
        }
        request.OrderToShip.SelectedInvoiceId = selectedInvoice?.Id || null;

        try {
            const unsubscribe = this.platformChannel.subscribe("shippinghub", "singleordershipped", (...args) => {
                this.orderShippedMessage.bind(this).apply(this, [presenter, ...args]);
                unsubscribe();
            });
            const order = request.OrderToShip;
            order.OrderRelatedShippingServices = request.ProductServices.filter(s => s.OnlyInOrder);
            order.ShippingProduct.ProductServices = request.ProductServices;
            await this.shippingRepository.shipSingleOrder(request.OrderToShip);
            this.setDefaults(order);
        } catch (e) {
            await this.errorHandler.handleException(e, "text.failed_to_ship_the_order", true);
            presenter?.singleShipFailed();
        }
    }

    private async loadShippingStationOptions(): Promise<ShippingStationOptions> {
        try {
            return await this.shippingRepository.getShippingStationOptions();
        } catch (e) {
            const message = this.translator.translate("flash.load_shipping_station_settings_failed");
            this.messagingService.showMessage(Message.transient({message})).then();
            throw e;
        }
    }

    private async validateRequest(request: ShipSingleOrderRequest): Promise<boolean> {
        const options = await this.loadShippingStationOptions();

        if (request.OrderToShip == null) {
            const message = this.translator.translate("flash.no_order_to_ship_given");
            this.messagingService.showMessage(Message.transient({message})).then();
            return false;
        }

        if (request.OrderToShip.OrderIsArchived === true) {
            const message = this.translator.translate("text.shipping.can_not_ship_archived_order");
            this.messagingService.showMessage(Message.transient({message})).then();
            return false;
        }

        if (request.ShippingDate == null) {
            const message = this.translator.translate("flash.no_shipping_date_given");
            this.messagingService.showMessage(Message.transient({message})).then();
            return false;
        }

        if (options.PositionsHaveToBeMarkedAsPacked && request.OrderToShip.OrderDetails.find((x) => !x.IsPacked)) {
            const message = this.translator.translate("flash.please_mark_positions_as_packed");
            this.messagingService.showMessage(Message.transient({message})).then();
            return false;
        }

        return true;
    }

    private async orderShippedMessage(presenter: SingleShipPresenter,
                                      orderId: number,
                                      success: boolean,
                                      message: string,
                                      infoMessages: string[],
                                      batchId: string,
                                      providerId: number,
                                      providerName: string,
                                      labelCount: number,
                                      exportDocCount: number,
                                      retoureLabelCount: number,
                                      fileTypeText: string,
                                      shippingId: string,
                                      orderRef: string,
                                      orderNumber: string,
                                      shippingAddress: Address,
    ): Promise<void> {
        const title = this.translator.translate(success ? "flash.shipment_created" : "title.shipment_failed");
        if (!success) {
            await this.messagingService.showError(Message.blocking({title, message}));
            presenter?.singleShipFailed();
            return;
        }

        this.eventService.dispatch(new SingleOrderShippedEvent({
            OrderId: orderId,
            Success: success,
            Message: message,
            InfoMessages: infoMessages,
            BatchId: batchId,
            ProviderId: providerId,
            ProviderName: providerName,
            LabelCount: labelCount,
            ExportDocCount: exportDocCount,
            RetoureLabelCount: retoureLabelCount,
            FileTypeText: fileTypeText,
            ShippingId: shippingId,
            OrderRef: orderRef,
            OrderNumber: orderNumber,
            ShippingAddress: shippingAddress,
        }));

        await this.messagingService.showMessage(Message.transient({title, message}));
        await this.navigationService.navigateToShipping();
    }

    private setDefaults(order: OrderToShip): void {
        const numberOrNull = (val: string | number | null) => val != null ? Number(val) : null;
        this.clientStorageService.set<number>("shipping.defaultProviderId", numberOrNull(order.ShippingProviderId));
        this.clientStorageService.set<number>("shipping.defaultProductId", numberOrNull(order.ShippingProductId));
        this.clientStorageService.set<number>("shipping.defaultPackageId", numberOrNull(order.ShippingPackageId));
        this.clientStorageService.set<number>("shipping.defaultCloudPrinter", numberOrNull(order.CloudStorageId));
        this.clientStorageService.set<number>("shipping.defaultCloudPrinterForExportDocs", numberOrNull(order.CloudStorageIdForExportDocs));
        this.clientStorageService.set<number>("shipping.defaultCloudPrinterForRetoureLabels", numberOrNull(order.CloudStorageIdForRetoureLabels));
    }

    private confirmDoubleShip(date: Date): Promise<boolean> {
        const title = this.translator.translate("title.order_already_shipped");
        const message = this.translator.translate("text.order_already_shipped");
        return this.interaction.confirm(title, message);
    }
}
